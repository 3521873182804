import React, {
	FocusEvent,
	forwardRef,
	ReactNode,
	useEffect,
	useState,
} from "react";
import { FieldError, UseFormRegister } from "react-hook-form";
import { Size, Variant } from "src/@types";
import { EyeOffIcon, EyeOnIcon } from "src/components";
import { ErrorMsg } from "../ErrorMsg/ErrorMsg";
import { Label } from "../Label/Label";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	name: string;
	label?: string;
	icon?: JSX.Element;
	inputSize?: Size;
	id: string;
	register: UseFormRegister<any>;
	error?: FieldError | undefined;
	requiredStar?: boolean | undefined;
	variant?: Variant;
	labelClassName?: string;
	containerClassName?: string;
	hasValue?: boolean;
}

interface CustomInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	label?: string;
	icon?: JSX.Element;
	inputSize?: Size;
	variant?: Variant;
	selectNode?: ReactNode;
	requiredStar?: boolean | undefined;
	error?: FieldError | undefined;
	labelClassName?: string;
}

const blurStyles = { label: {}, input: {} };

const focusStyles = {
	label: {
		top: "-11px",

		fontSize: "14px",
		background: "#FEFEFE",
	},
	input: { borderColor: "#0077B4" },
};

const renderSize = (inputSize: Size) => {
	switch (inputSize) {
		case "sm":
			return "py-2";
		case "md":
			return "py-3";
		case "lg":
			return "py-4";
		default:
			return "py-2";
	}
};

const renderVariant = (variant: Variant) => {
	switch (variant) {
		case "standard":
			return "input--standard";
		case "filled":
			return "input--filled";
		case "basic":
			return "input--basic";
		default:
			return "input--standard";
	}
};

export const Input = ({
	type,
	id,
	name,
	label,
	inputSize,
	requiredStar,
	icon,
	placeholder,
	register,
	error,
	min,
	max,
	variant,
	className,
	labelClassName,
	containerClassName,
	hasValue,
	...rest
}: InputProps) => {
	const [styles, setStyles] = useState(hasValue ? focusStyles : blurStyles);

	const focusHandler = () => {
		setStyles(focusStyles);
	};

	const BlurHandler = (e: FocusEvent<HTMLInputElement>) => {
		if (!e.target.value) {
			setStyles(blurStyles);
		}
	};

	return (
		<div className={`relative ${containerClassName}`}>
			{(label || variant === "basic") && (
				<Label
					required={requiredStar}
					htmlFor={id}
					style={styles.label}
					className={labelClassName}
					variant={variant}
					error={error}
				>
					{label}
				</Label>
			)}

			<div className="relative">
				{icon && (
					<span
						className={` absolute top-3 start-2  ${
							error && error.message ? "text-red2" : "text-muted"
						} `}
					>
						{icon}
					</span>
				)}

				<input
					type={type}
					id={id}
					className={`
						input relative
						${renderSize(inputSize)}
						${renderVariant(variant)}
						${error && error.message ? "input--error" : ""}
						${icon ? "ps-10" : "ps-3"}
						${className}
          `}
					placeholder={placeholder}
					min={min}
					max={max}
					autoComplete="off"
					style={styles.input}
					{...register(name, {
						onBlur: (e) => BlurHandler(e),
						onChange: () => focusHandler(),
					})}
					onFocus={focusHandler}
					// ref={ref}
					{...rest}
				/>
			</div>

			{error && error.type !== "required" && error.type !== "typeError" && (
				<ErrorMsg>{error.message}</ErrorMsg>
			)}
		</div>
	);
};

const CustomInputComponent = (
	{
		type,
		id,
		name,
		value,
		inputSize,
		variant,
		onChange,
		onBlur,
		error,
		label,
		selectNode,
		requiredStar,
		icon,
		placeholder,
		min,
		className,
		labelClassName,
		...rest
	}: CustomInputProps,
	ref: React.ForwardedRef<HTMLInputElement>
) => {
	const [styles, setStyles] = useState(blurStyles);

	const focusHandler = () => {
		setStyles(focusStyles);
	};

	const BlurHandler = (e: FocusEvent<HTMLInputElement>) => {
		if (value) {
			setStyles(blurStyles);
		}
	};

	return (
		<div className="relative">
			{(label || variant === "basic") && (
				<Label
					required={requiredStar}
					htmlFor={id}
					style={styles.label}
					className={labelClassName}
					variant={variant}
					error={error}
				>
					{label}
				</Label>
			)}

			<div className={`relative ${selectNode ? "flex items-center" : ""}`}>
				{icon && (
					<span
						className={`
							absolute top-1/2 -translate-y-1/2 start-2
							${error ? "text-red2" : "text-muted"}
						`}
					>
						{icon}
					</span>
				)}

				{selectNode ? selectNode : null}

				<input
					type={type}
					id={id}
					name={name}
					className={`
						input
						${renderSize(inputSize)}
						${renderVariant(variant)}
						${error ? "input--error" : ""}
						${icon ? "ps-10" : "ps-3"}
						${className}
					`}
					onChange={onChange}
					onBlur={(e) => {
						if (onBlur) {
							onBlur(e);
						}
						BlurHandler(e);
					}}
					value={value}
					placeholder={placeholder}
					min={min}
					autoComplete="off"
					onFocus={() => {
						focusHandler();
					}}
					ref={ref}
					{...rest}
				/>
			</div>

			{error && error.type !== "required" && error.type !== "typeError" && (
				<ErrorMsg>{error.message}</ErrorMsg>
			)}
		</div>
	);
};

export const CustomInput = forwardRef(CustomInputComponent);

export const PasswordInput = ({
	id,
	name,
	inputSize,
	placeholder,
	register,
	error,
	min,
	max,
	label,
	variant,
}: InputProps) => {
	const [showPassword, setShowPassword] = useState(false);
	const [styles, setStyles] = useState(blurStyles);
	const focusHandler = () => {
		setStyles(focusStyles);
	};

	const BlurHandler = (e: FocusEvent<HTMLInputElement>) => {
		if (!e.target.value) {
			setStyles(blurStyles);
		}
	};

	return (
		<div className="relative">
			{(label || variant === "basic") && (
				<Label
					htmlFor={id}
					style={styles.label}
					variant={variant}
					error={error}
				>
					{label}
				</Label>
			)}

			<input
				type={showPassword ? "text" : "password"}
				id={id}
				className={`input   ps-3 pe-10 ${renderSize(inputSize)} ${renderVariant(
					variant
				)} ${error && error.message ? "input--error" : ""}`}
				placeholder={placeholder}
				min={min}
				max={max}
				autoComplete="off"
				{...register(name, {
					onBlur: (e) => BlurHandler(e),
					onChange: () => focusHandler(),
				})}
				onFocus={focusHandler}
			/>

			<span
				className={"absolute top-3 right-6 cursor-pointer text-gray-800"}
				onClick={() => setShowPassword((prev) => !prev)}
			>
				{showPassword ? (
					<EyeOnIcon width={20} height={20} />
				) : (
					<EyeOffIcon width={20} height={20} />
				)}
			</span>

			{error && error.type !== "required" && error.type !== "typeError" && (
				<ErrorMsg>{error.message}</ErrorMsg>
			)}
		</div>
	);
};

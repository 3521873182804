import { createSlice } from "@reduxjs/toolkit";
import {
	PatientFileProcedureType,
	SelectedTeethDictionaryType,
	PatientFilePlanType,
	PatientFileSelectedPlanTeethDictionaryType,
} from "src/@types";
import { convertSelectedPlanTeethToDictionary } from "./utils";
import {
	createPatientPlan,
	deletePatientPlan,
	updatePlan,
} from "./asyncActions";
import {
	clearReducers,
	diagnosesReducers,
	plansReducers,
	proceduresReducers,
	screensReducers,
	stageReducers,
	teethReducers,
	upperBarReducers,
} from "./reducers";

type Screens = "PROCEDURES" | "DIAGNOSIS";

export type PatientProfileState = {
	currentScreen: Screens;
	isUpperBarOpen: boolean;

	plans: PatientFilePlanType[];
	selectedPlan: PatientFilePlanType | null;
	selectedPlanTeethDictionary: PatientFileSelectedPlanTeethDictionaryType | null;

	selectedProcedure: PatientFileProcedureType | null;

	selectedTeeth: SelectedTeethDictionaryType;
	isUpperArchSelected: boolean;
	isLowerArchSelected: boolean;

	hoveredTeeth: SelectedTeethDictionaryType;
};

const initialState: PatientProfileState = {
	currentScreen: "DIAGNOSIS",
	isUpperBarOpen: false,

	plans: [],
	selectedPlan: null,
	selectedPlanTeethDictionary: null,

	selectedProcedure: null,

	selectedTeeth: {},
	isUpperArchSelected: false,
	isLowerArchSelected: false,

	hoveredTeeth: {},
};

const patientProfileSlice = createSlice({
	name: "patientProfile",
	initialState,
	reducers: {
		...stageReducers,
		...diagnosesReducers,
		...plansReducers,
		...upperBarReducers,
		...screensReducers,
		...teethReducers,
		...proceduresReducers,
		...clearReducers,
	},

	extraReducers: (builder) => {
		builder.addCase(createPatientPlan.fulfilled, (state, action) => {
			state.plans?.push(action.payload.plan);
			state.selectedPlan = action.payload.plan;
			state.selectedPlanTeethDictionary = convertSelectedPlanTeethToDictionary({
				plan: action.payload.plan,
			});
		});

		builder.addCase(deletePatientPlan.fulfilled, (state, action) => {
			if (state.plans && state.plans.length > 1) {
				state.plans = state.plans?.filter(
					(plan) => plan.id !== action.payload.planId
				);

				// * If this plan selected then select the first plan
				if (state.selectedPlan?.id === action.payload.planId && state.plans) {
					state.selectedPlan = state.plans[0];
					state.selectedPlanTeethDictionary =
						convertSelectedPlanTeethToDictionary({ plan: state.plans[0] });
				}
			}
		});

		builder.addCase(updatePlan.fulfilled, (state, action) => {
			if (state.plans) {
				state.plans = state.plans.map((plan) => {
					if (plan.id === action.payload.plan.id) {
						return action.payload.plan;
					}

					return plan;
				});
			}

			if (state.selectedPlan?.id === action.payload.plan.id) {
				state.selectedPlan = action.payload.plan;

				state.selectedPlanTeethDictionary =
					convertSelectedPlanTeethToDictionary({
						plan: action.payload.plan,
					});
			}
		});
	},
});

export const patientProfileActions = patientProfileSlice.actions;

export const patientProfileReducer = patientProfileSlice.reducer;

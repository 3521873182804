import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	AppointmentsOverviewType,
	AssistantOverviewType,
	AssistantTableOverviewType,
	ClinicsOverviewType,
	DentistsOverviewType,
	DentistsTableOverviewType,
	ExpensesOverviewType,
	ExpensesTableOverviewType,
	FinanceOverviewType,
	LabOrdersTableOverviewType,
	LabWorkOverviewType,
	PatientsOverviewType,
	ProceduresOverviewType,
	ReportsFetch,
	RevenueOverviewType,
	SummaryOverviewType,
} from "../../../@types/Overview.type";

export interface ReportsState {
	summary: SummaryOverviewType;
	assistant: AssistantOverviewType;
	assistant_table: AssistantTableOverviewType;
	patients: PatientsOverviewType;
	clinics: ClinicsOverviewType;
	appointments: AppointmentsOverviewType;
	dentists: DentistsOverviewType;
	dentists_table: DentistsTableOverviewType;
	procedures: ProceduresOverviewType;
	lab_works: LabWorkOverviewType;
	lab_orders_table: LabOrdersTableOverviewType;
	finance: FinanceOverviewType;
	expenses: ExpensesOverviewType;
	expenses_table: ExpensesTableOverviewType;
	revenue: RevenueOverviewType;
}

const initialState: ReportsState = {
	summary: {
		patients: {
			count: 0,
			visits: 0,
			insurances: 0,
		},
		production: {
			visits: {},
			procedures: {},
		},
		total: {
			expenses: 0,
			productions: 0,
		},
		due: {
			insurances_due: 0,
			total: 0,
			insurances_total: 0,
			insurances_total_installments: 0,
		},
		collections: {
			total: 0,
			credit: 0,
			cash: 0,
		},
		hours: {
			appointments: 0,
			clinics: 0,
		},
		capacity: 0,
	},
	assistant: {
		opportunity_percent: 0,
		plans_count: 0,
		total: {
			received_amount: 0,
			receivable_amount: 0,
		},
	},
	assistant_table: {
		patients: {},
		dentists: {},
		plans_status: {},
		plans_procedures_names: {},
		// plans_procedures_total: {},
		plans_total: {},
		// procedures: [],
		plans: [],
	},
	patients: {
		patients: {
			total: {
				count: 0,
				percent: 0,
			},
			males: {
				count: 0,
				percent: 0,
			},
			females: {
				count: 0,
				percent: 0,
			},
		},
		visits_per_day: {},
		revenue: {
			day: {
				count: 0,
				percent: 0,
			},
			month: {
				count: 0,
				percent: 0,
			},
			year: {
				count: 0,
				percent: 0,
			},
		},
		patients_by_age: {},
		patients_by_gender: {
			"0": {},
			"1": {},
		},
		patients_by_nationality: {},
	},
	clinics: {
		visits: {
			per_day: {},
			per_clinic: {},
		},
		total: {
			clinics: 0,
			hours: 0,
		},
		hours_per_clinic: {},
	},
	appointments: {
		count: {
			total: 0,
			confirmed: 0,
			missed: 0,
			arrive_in: 0,
			cancelled: 0,
		},
		count_per_day: {},
		waiting_per_day: {},
	},
	dentists: {
		dentists: [],
		production_per_dentist_per_day: {
			per_day: {},
			per_dentist: {},
		},
		dentists_rank: {},
		invoices: [],
	},
	dentists_table: {
		dentists: {},
		patients: {},
		invoices: [],
	},
	procedures: {
		procedures_per_day: {},
		procedures_rank: {},
	},
	lab_works: {
		total: {
			orders: 0,
			cost: 0,
			avg_reviews: 0,
			assigned: 0,
			waiting: 0,
			received: 0,
			reassigned: 0,
			delivered: 0,
		},
		orders: [],
	},
	lab_orders_table: {
		labs: {},
		dentists: {},
		patients: {},
		orders: [],
	},
	finance: {
		total: {
			revenue: 0,
			expenses: 0,
		},
		revenue_per_day: {},
		expenses_per_day: {},
	},
	expenses: {
		total: 0,
		expenses_per_day: {},
		expenses_per_category: {},
		expenses_table: [],
	},
	expenses_table: {
		users: {},
		categories: [],
		expenses: [],
	},
	revenue: {
		total: {
			revenue: 0,
			collections: 0,
			cash_collections: 0,
			credit_collections: 0,
			due: 0,
		},
		revenue_per_day: {},
		revenue_table: [],
	},
};

const reportsSlice = createSlice({
	name: "reports",
	initialState,
	reducers: {
		setReports(state, { payload }: PayloadAction<ReportsFetch>) {
			state.summary = payload.summary;
			state.patients = payload.patients;
			state.clinics = payload.clinics;
			state.appointments = payload.appointments;
			state.dentists = payload.dentists;
			state.lab_works = payload.lab_works;
			state.procedures = payload.procedures;
			state.finance = payload.finance;
			state.expenses = payload.expenses;
			state.revenue = payload.revenue;
		},
		setDentistsTable(
			state,
			{ payload }: PayloadAction<DentistsTableOverviewType>
		) {
			state.dentists_table = payload;
		},
		setLabOrdersTable(
			state,
			{ payload }: PayloadAction<LabOrdersTableOverviewType>
		) {
			state.lab_orders_table = payload;
		},
		setExpensesTable(
			state,
			{ payload }: PayloadAction<ExpensesTableOverviewType>
		) {
			state.expenses_table = payload;
		},
		setAssistant(state, { payload }: PayloadAction<AssistantOverviewType>) {
			state.assistant = payload;
		},
		setAssistantTable(
			state,
			{ payload }: PayloadAction<AssistantTableOverviewType>
		) {
			state.assistant_table = payload;
		},
	},
});

export const {
	setReports,
	setDentistsTable,
	setLabOrdersTable,
	setExpensesTable,
	setAssistant,
	setAssistantTable,
} = reportsSlice.actions;

export const reportsReducer = reportsSlice.reducer;

import axios from "axios";
import { ROUTES } from "../constants";

const instance = axios.create({
	// baseURL: "http://192.168.1.101:7000/",
	baseURL: "https://staging.beclarified.com/",
	withCredentials: true,
});

instance.interceptors.request.use(
	async (config) => {
		const token = localStorage.getItem("token");

		config.headers = {
			Authorization: `Bearer ${token}`,
		};

		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

instance.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error.response && error.response.status === 401) {
			window.location.href = ROUTES.AUTH.LOGIN;
			localStorage.clear();
		}

		return Promise.reject(error);
	}
);

export default instance;

import { PatientFileProcedureType } from "src/@types";
import { patientProfileActions } from "../../../patientProfileSlice";
import { updatePlan } from "../../plan";
import { handleUpdateAssignedTo } from "./handleUpdateAssignedTo";
import { handleUpdatePrice } from "./handleUpdatePrice";
import { handleUpdateStatus } from "./handleUpdateStatus";

type UpdateAssignedToType = {
	assignedTo: PatientFileProcedureType["assignedTo"];
	basePrice?: never;
	discount?: never;
	total?: never;
	status?: never;
	procedureId?: never;
	stageId?: never;
};

type UpdatePriceType = {
	basePrice: PatientFileProcedureType["basePrice"];
	discount: PatientFileProcedureType["discount"];
	total: PatientFileProcedureType["total"];
	assignedTo?: never;
	status?: never;
	procedureId?: never;
	stageId?: never;
};

type UpdateStatusType = {
	status: PatientFileProcedureType["status"];
	stageId: PatientFileProcedureType["stageId"];
	procedureId: PatientFileProcedureType["id"];
	assignedTo?: never;
	basePrice?: never;
	discount?: never;
	total?: never;
};

type UpdateProcedureParamsType =
	| UpdateAssignedToType
	| UpdatePriceType
	| UpdateStatusType;

export const updateProcedure = ({
	assignedTo,

	basePrice,
	discount,
	total,

	procedureId,
	stageId,
	status,
}: UpdateProcedureParamsType) => {
	return updatePlan({
		updatedPlan: (planToUpdate, state, dispatch) => {
			const isAssignedToUpdated = assignedTo !== undefined;

			const isPriceUpdated =
				basePrice !== undefined &&
				discount !== undefined &&
				total !== undefined;

			const isStatusUpdated =
				status !== undefined &&
				stageId !== undefined &&
				procedureId !== undefined;

			const updatedPlan = isAssignedToUpdated
				? handleUpdateAssignedTo({ planToUpdate, assignedTo, state })
				: isPriceUpdated
				? handleUpdatePrice({
						planToUpdate,
						basePrice,
						discount,
						total,
						state,
						dispatch,
				  })
				: isStatusUpdated
				? handleUpdateStatus({
						planToUpdate,
						state,
						stageId,
						procedureId,
						status,
				  })
				: planToUpdate;

			return updatedPlan;
		},
		handleSuccess(plan, dispatch) {
			dispatch(patientProfileActions.updateProcedureSuccess({ plan }));
		},
	});
};

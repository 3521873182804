import { forwardRef } from "react";
import styles from "./CheckBox.module.css";

interface CircleCheckBoxProps
	extends React.DetailedHTMLProps<
		React.InputHTMLAttributes<HTMLInputElement>,
		HTMLInputElement
	> {
	circle?: boolean;
	inputSize?: "sm" | "md" | "xs";
}

export const CustomCheckBox = forwardRef(
	(
		{
			id,
			name,
			className,
			onChange,
			onBlur,
			value,
			checked,
			circle,
			readOnly,
			inputSize = "md",
			...rest
		}: CircleCheckBoxProps,
		ref: React.ForwardedRef<HTMLInputElement>
	) => {
		const renderSize = () => {
			switch (inputSize) {
				case "sm":
					return { input: "w-5 h-5", checkMark: "checkmarkSmall" };
				case "md":
					return { input: "w-6 h-6", checkMark: "checkmarkMedium" };
				case "xs":
					return { input: "w-4 h-4", checkMark: "checkmarkXSmall" };
				default:
					return { input: "w-6 h-6", checkMark: "checkmarkMedium" };
			}
		};

		return (
			<div className={className}>
				<input
					type="checkbox"
					id={id}
					className={`${styles.checkboxInput}  sr-only`}
					name={name}
					checked={checked}
					value={value}
					onChange={onChange}
					onBlur={onBlur}
					readOnly={readOnly}
					ref={ref}
					{...rest}
				/>

				<label
					htmlFor={id}
					className={`relative block flex-shrink-0 cursor-pointer border border-gray-400 bg-white ${
						renderSize().input
					} ${circle ? "rounded-full" : "rounded-md"} ${styles.customLabel}`}
					tabIndex={0}
				>
					<span
						className={`${styles.checkmark} ${styles[renderSize().checkMark]}`}
					></span>
				</label>
			</div>
		);
	}
);

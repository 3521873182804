import React, { Suspense, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { AppSpinner, SmallScreensMessage } from "src/components";
import { useAppSelector } from "../redux";
import { ToastContainer } from "react-toastify";
import { PrivateRouter } from "src/routers";
import { ROUTES } from "../constants/index";
import { AuthApp } from "./AuthApp";
import { useCheckRole } from "src/hooks";
import "./App.css";
import { useTranslation } from "react-i18next";
import { Register } from "src/pages";

const AssistantApp = React.lazy(() => import("./AssistantApp"));
const DentistApp = React.lazy(() => import("./DentistApp"));
const AdminApp = React.lazy(() => import("./AdminApp"));

const App = () => {
	const { i18n } = useTranslation();
	const { isLoggedIn } = useAppSelector((state) => state.auth.login);
	const { isAdmin, isAssistant, isDentist, notAuthorized } = useCheckRole();

	const [isPortrait, setIsPortrait] = useState(false);
	const showSmallScreensMessage = isPortrait && isLoggedIn;

	useEffect(() => {
		const mediaQuery = matchMedia("(orientation: portrait)");
		const handleOrientationChange = () => setIsPortrait(mediaQuery.matches);

		mediaQuery.addEventListener("change", handleOrientationChange);

		const timeoutId = setTimeout(() => {
			setIsPortrait(mediaQuery.matches);
		}, 1000);

		return () => {
			mediaQuery.removeEventListener("change", handleOrientationChange);
			clearTimeout(timeoutId);
		};
	}, []);

	if (showSmallScreensMessage) {
		document
			.querySelector("meta[name=viewport]")
			?.setAttribute(
				"content",
				"width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
			);
	} else {
		document
			.querySelector("meta[name=viewport]")
			?.setAttribute("content", "width=1366");
	}

	return (
		<>
			{showSmallScreensMessage && <SmallScreensMessage />}

			{!showSmallScreensMessage && (
				<>
					<ToastContainer
						position="top-center"
						autoClose={5000}
						hideProgressBar={false}
						newestOnTop={false}
						closeOnClick
						rtl={i18n.dir() === "rtl"}
						pauseOnFocusLoss
						draggable
						pauseOnHover
						theme="dark"
					/>

					<Switch>
						<Route path={ROUTES.AUTH.BASE}>
							<AuthApp />
						</Route>

						<Route path={ROUTES.ON_BOARDING.BASE}>
							<Register />
						</Route>

						<PrivateRouter
							path={ROUTES.ASSISTANT.BASE}
							isAuth={isLoggedIn && isAssistant}
						>
							<Suspense fallback={<AppSpinner />}>
								<AssistantApp />
							</Suspense>
						</PrivateRouter>

						<PrivateRouter
							path={ROUTES.DENTIST.BASE}
							isAuth={isLoggedIn && isDentist}
						>
							<Suspense fallback={<AppSpinner />}>
								<DentistApp />
							</Suspense>
						</PrivateRouter>

						<PrivateRouter path={ROUTES.ADMIN.BASE} isAuth={isAdmin}>
							<Suspense fallback={<AppSpinner />}>
								<AdminApp />
							</Suspense>
						</PrivateRouter>

						{isAssistant && (
							<Redirect from="/" to={ROUTES.ASSISTANT.SCHEDULER} />
						)}
						{isAdmin && <Redirect from="/" to={ROUTES.ADMIN.HOME} />}
						{isDentist && <Redirect from="/" to={ROUTES.DENTIST.HOME} />}
						{notAuthorized && <Redirect from="/" to={ROUTES.AUTH.LOGIN} />}

						<Redirect
							from={ROUTES.ASSISTANT.BASE}
							to={ROUTES.ASSISTANT.SCHEDULER}
						/>
						<Redirect from={ROUTES.ADMIN.BASE} to={ROUTES.ADMIN.HOME} />
						<Redirect from={ROUTES.DENTIST.BASE} to={ROUTES.DENTIST.HOME} />

						<Redirect from="*" to="/404" />
					</Switch>
				</>
			)}
		</>
	);
};

export default App;

import { configureStore } from "@reduxjs/toolkit";
import {
	patientListReducer,
	headerTitleReducer,
	authReducer,
	patientsReducer,
	dentistsReducer,
	insurancesReducer,
	eventsReducer,
	schedulerReducer,
	patientProfileReducer,
	labsReducer,
	servicesReducer,
	stockReducer,
	reportsReducer,
} from "../slices";

export const store = configureStore({
	reducer: {
		headerTitle: headerTitleReducer,
		scheduler: schedulerReducer,
		patientListPage: patientListReducer,
		auth: authReducer,
		events: eventsReducer,
		patients: patientsReducer,
		dentists: dentistsReducer,
		insurances: insurancesReducer,
		labs: labsReducer,
		services: servicesReducer,
		stock: stockReducer,
		patientProfile: patientProfileReducer,
		reports: reportsReducer,
	},
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
